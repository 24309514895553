<template>
  <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    :type="type"
    :variant="variant"
    :disabled="disabled || isLoading"
    block
    :class="btnclass"
    :href="href"
    @click="$emit('click', $event)"
  >
    <b-spinner v-if="isLoading" small />
    <slot>Button</slot>
  </b-button>
</template>

<script>
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnclass: {
      type: String,
      default: '',
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
  },
}
</script>
